import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL, API_ENDPOINT_LOGIN, AUTH_TOKENS_KEY } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const loginUser = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_LOGIN}`, data);
    const responseData = await res.data;
    sessionStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(responseData.data))
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});
export { loginUser };
