import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL, API_ENDPOINT_ADD_USER } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const addUser = createAsyncThunk("user/add", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_ADD_USER}`, data);
    const responseData = await res.data;
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});
export { addUser };
