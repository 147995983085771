import axios from "axios";
import { history } from "../utils/history";
import { customDispatch } from "../utils/customDispatch";
import { reset } from "../store";
import {
  getContentType,
} from "./helper";

import {
  BASE_URL,
  AUTH_TOKENS_KEY,
} from "./constants";
import { showError } from "./notification";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isSessionExpired = false; // Flag to track session expiration

axiosInstance.interceptors.request.use(
  (config) => {
    if (config?.url) {
      const contentType = getContentType(config?.url);
      config.headers["Content-Type"] = contentType;
    }

    return config;
  },
  (error) => {
    console.log("ERROR:", error)
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    // Reset the session expiration flag if the response is successful
    isSessionExpired = false;
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    console.log("Config:", err.response.status)
    const authTokens = JSON.parse(sessionStorage.getItem(AUTH_TOKENS_KEY));

    if (typeof err.response === "undefined") {
      alert(
        "A server/network error occurred. " +
        "Looks like CORS might be the problem. " +
        "Sorry about this - we will get it fixed shortly."
      );
      return Promise.reject(err);
    }

    if (err.response.status === 401) {
      if (!isSessionExpired) {
        isSessionExpired = true; // Set the flag to true
        sessionStorage.clear();
        localStorage.clear();
        customDispatch.dispatch(reset()); // reset store
        showError("Session has expired! Please relogin.");
      }
      history.navigate("/", { replace: true });
      return Promise.reject(err);
    }
    history.navigate("/", { replace: true });
    return Promise.reject(err);
  }
);

export default axiosInstance;
