import { createSlice } from "@reduxjs/toolkit";
import { fetchDishesOfCuisine } from "../thunks/cuisines/fetchDishesOfCuisine";
import { fetchTeaCoffee } from "../thunks/cuisines/fetchTeaCoffee";
import { reset } from "../actions/resetActions";


const initialState = {
    isLoading: false,
    error: null,

    dishesData: null,
    dishesLenghtCheck: false,
    pageNumber: 1,
    prevPage: 1,
    filterApplied: false,

    teaCoffeeData: null,
    teaLenghtCheck : false
}

const cuisineDishesSlice = createSlice({
    name: "cuisines",
    initialState,
    reducers: {
        resetData: (state, action) => {
            state.error = "";
            state.isLoading = false;
            state.dishesData = null;
            state.dishesLenghtCheck = false
            state.prevPage = 1
            state.pageNumber = 1
            state.teaCoffeeData = null
            state.teaLenghtCheck = false
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload
        },
        setPrevPage: (state, action) => {
            state.prevPage = action.payload
        },
        setDishesLenghtCheck: (state, action) => {
            state.dishesLenghtCheck = action.payload
        },
        setFilterApplied: (state, action) => {
            state.filterApplied = action.payload
        }
    },

    extraReducers: (builder) => {
        // reset
        builder.addCase(reset, () => initialState);

        //CUISINES DATA
        builder.addCase(fetchDishesOfCuisine.pending, (state) => {
            state.dishesLenghtCheck = false
        });
        builder.addCase(fetchDishesOfCuisine.fulfilled, (state, action) => {
            if (action.payload.data?.length > 49) {
                state.dishesData = action.payload.data
                state.dishesLenghtCheck = false
            } else {
                if (action.payload.data.length > 0) {
                    state.dishesData = action.payload.data
                }
                state.dishesLenghtCheck = true
                if (state.pageNumber === 1) {
                    state.dishesData = action.payload.data
                }
            }
            state.success = action?.payload?.message;

        });
        builder.addCase(fetchDishesOfCuisine.rejected, (state, action) => {
            state.dishesData = null;
            state.error = action?.payload?.message;
            state.dishesLenghtCheck = false
        });

        // Tea Coffee data
        builder.addCase(fetchTeaCoffee.pending, (state) => {
        });
        builder.addCase(fetchTeaCoffee.fulfilled, (state, action) => {
            if (action.payload.data?.length > 49) {
                state.teaCoffeeData = action.payload.data
                state.teaLenghtCheck = false
            } else {
                if (action.payload.data.length > 0) {
                    state.teaCoffeeData = action.payload.data
                }
                state.teaLenghtCheck = true
                if (state.pageNumber === 1) {
                    state.teaCoffeeData = action.payload.data
                }
            }
            state.teaCoffeeData = action.payload.data
            state.success = action?.payload?.message;

        });
        builder.addCase(fetchTeaCoffee.rejected, (state, action) => {
            state.teaCoffeeData = null;
            state.error = action?.payload?.message;
            state.teaLenghtCheck = false
        });
    }
})

export const dishesAction = cuisineDishesSlice.actions;
export const dishesReducer = cuisineDishesSlice.reducer;