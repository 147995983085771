import React, { useState, useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { authActions, dashboardActions } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { showSuccess } from '../utils/notification';
import { LOGOUT_MESSAGE } from '../utils/constants';
import { manipulateString } from '../utils/helper';
import styles from '../shared/common.module.css';

function UserLeftBar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { resetState } = authActions;
    const { setReloaded, setratingsChecked, resetData, setFoodType, setLocation1, setLocation2, setLocation3, setAllDropdown, setAppliedFilter } = dashboardActions;
    const { isSignedIn, role, userData } = useSelector((state) => state.auth)
    const [activeMenu, setActiveMenu] = useState(location.pathname)


    function navigateToQuarterly() {
        setActiveMenu("/quarterly-trend")
        navigate("/quarterly-trend");
        dispatch(setReloaded(true))
        dispatch(setratingsChecked([]))
        dispatch(resetData());
    }
    function navigateToDashboard() {
        setActiveMenu("/dashboard")
        navigate("/dashboard");
        dispatch(setReloaded(true))
        dispatch(setratingsChecked([]))
        dispatch(resetData());
        dispatch(setAppliedFilter(true))
    }
    function navigateToProfile() {
        setActiveMenu("/profile")
        navigate("/profile");
        dispatch(setReloaded(true))
        dispatch(setratingsChecked([]))
        dispatch(resetData());
    }
    function navigateToUserList() {
        setActiveMenu("/admin-list")
        const slug = localStorage.getItem("country_slug")
        sessionStorage.setItem("country_id", slug)
        navigate("/admin-list");
        dispatch(setReloaded(true))
        dispatch(setratingsChecked([]))
        dispatch(resetData());
    }

    const logoutHandler = () => {
        dispatch(resetState());
        dispatch(resetData());
        showSuccess(LOGOUT_MESSAGE)
        dispatch(setReloaded(true))
        dispatch(setratingsChecked([]))
        dispatch(setFoodType(1))
        dispatch(setLocation1([]))
        dispatch(setLocation2([]))
        dispatch(setLocation3([]))
        dispatch(setAllDropdown(null))
        dispatch(setAppliedFilter(true))
        sessionStorage.clear();
        localStorage.clear();
    }

    useEffect(() => {
        if (!isSignedIn) {
            navigate("/");
        }
    }, [isSignedIn, navigate]);


    return (
        <>
            <aside className='flex-none flex md:flex-col justify-between relative items-center gap-4 md:block bg-green py-1 md:py-4 md:h-dvh w-full md:w-44'>
                <div className='md:pb-3 flex flex-col px-4 md:border-b border-slate'>
                    <button type='button' className='w-full text-md text-white uppercase leading-none text-center'>Kerry</button>
                </div>
                <div className='md:pt-10 hidden md:block flex-none menuNav'>
                    <div className={`${styles.profile} ttBox flex flex-col justify-center items-center relative mt-1 mb-4 mx-3`}>
                        <div className={styles.dpHldr}>
                            <PersonIcon />
                        </div>
                        <h4 className='flex-none w-full text-white text-ellipsis whitespace-nowrap overflow-hidden mb-3 px-2 text-center'>{manipulateString(userData?.name)}</h4>
                        <div className='flex-none w-24 h-1 border-t border-t-white mx-auto block'>&nbsp;</div>
                        <div className='ttBoxTxtPane pt-0'>
                            <div className='ttBoxPaneInner rounded-lg'>
                                <p className='text-black font-bold m-0 text-xs sm:text-xs whitespace-pre-wrap'>{manipulateString(userData?.name)}</p>
                                <p className='text-gray m-0 text-xs sm:text-xs w-full text-ellipsis whitespace-nowrap overflow-hidden'>{userData?.email}</p>
                                <p className='text-gray m-0 text-xs sm:text-xs whitespace-pre-wrap'>{userData?.country?.name}</p>
                            </div>
                        </div>
                    </div>
                    <button onClick={navigateToDashboard} className={`${activeMenu === "/dashboard" ? 'active' : ''} flex gap-3 w-full px-4 py-3 items-center md:mb-1 text-white text-lg cursor-pointer`}>
                        <DashboardIcon /><label className='text-sm cursor-pointer'>Dashboard</label>
                    </button>
                    {
                        role === "admin" && (
                            <button onClick={navigateToUserList} className={`${activeMenu === '/admin-list' ? 'active' : ''} flex gap-3 w-full px-4 py-3 items-center md:mb-1 text-white text-lg cursor-pointer`}>
                                <PersonIcon /><label className='text-sm cursor-pointer'>User List</label>
                            </button>
                        )
                    }
                    {/* {
                        role !== 'admin' && (
                            <button onClick={navigateToProfile} className={`${activeMenu === '/profile' ? 'active' : ''} flex gap-3 w-full px-4 py-3 items-center md:mb-1 text-white text-lg cursor-pointer`}>
                                <PersonIcon /><label className='text-sm cursor-pointer'>Profile</label>
                            </button>
                        )
                    } */}
                    <button onClick={logoutHandler} className={`${activeMenu === true ? 'active' : ''} flex gap-3 w-full px-4 py-3 items-center md:mb-1 text-white text-lg cursor-pointer`}>
                        <LogoutIcon /><label className='text-sm cursor-pointer'>Logout</label>
                    </button>
                </div>
                {/* Mobile */}
                <div className='flex-none block md:hidden pr-6'>
                    <button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        className='py-2 flex gap-1 items-center text-white hover:opacity-70 text-lg cursor-pointer'
                        onClick={handleClick}
                    >
                        <PersonIcon className='cursor-pointer' />
                        <ExpandMoreIcon className='cursor-pointer' />
                    </button>
                    <Menu
                        id="basic-menu"
                        className='hdrAccMenu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={navigateToDashboard} className={`${activeMenu === "/dashboard" ? 'active' : ''} flex gap-3 w-full items-center md:mb-4 text-white hover:opacity-70 text-lg cursor-pointer`}>
                            <DashboardIcon /><label className='text-sm cursor-pointer'>Dashboard</label>
                        </MenuItem>
                        <MenuItem onClick={navigateToProfile} className={`${activeMenu === "/profile" ? 'active' : ''} flex gap-3 w-full items-center md:mb-4 text-white hover:opacity-70 text-lg cursor-pointer`}>
                            <PersonIcon /><label className='text-sm cursor-pointer'>Profile</label>
                        </MenuItem>
                        <MenuItem onClick={logoutHandler} className='flex gap-3 w-full items-center md:mb-4 text-white hover:opacity-70 text-lg cursor-pointer'>
                            <LogoutIcon /><label className='text-sm cursor-pointer'>Logout</label>
                        </MenuItem>
                    </Menu>
                </div>
            </aside>
        </>
    );
}
export default UserLeftBar;

