import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL, API_ENDPOINT_FIRST_LOGIN } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const firstLoginUser = createAsyncThunk("auth/firstLogin", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_FIRST_LOGIN}`, data);
    const responseData = await res.data;
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});
export { firstLoginUser };
