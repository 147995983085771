import { toast } from "react-toastify";
import { TOAST_AUTOCLOSE_DURATION } from "./constants";

// toast.configure();

/**
 * success
 */
export const showSuccess = (success) => {
  return toast.success(success, {
    position: "top-right",
    className: "toast-message",
    autoClose: TOAST_AUTOCLOSE_DURATION, // default -> false
    icon: ({theme, type}) =>  <img src="/images/svg/mdi_tick-all.svg"/>
  });
};

/**
 * error
 */
export const showError = (message) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: TOAST_AUTOCLOSE_DURATION, // default -> false
  });
};
/**
 * warning
 */
export const showWarning = (message) => {
  return toast.warning(message, {
    position: "top-right",
    className: "toast-message",
    autoClose: TOAST_AUTOCLOSE_DURATION, // default -> false
  });
};
/**
 * info
 */
export const showInfo = (message) => {
  return toast.info(message, {
    className: "toast-message",
    autoClose: TOAST_AUTOCLOSE_DURATION, // default -> false
  });
};
