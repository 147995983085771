import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL, API_ENDPOINT_DROPDOWNS } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const dynamicDropDown = createAsyncThunk("dashboard/dropdown", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_DROPDOWNS}`, data);
    const responseData = await res.data;
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});
export { dynamicDropDown };
