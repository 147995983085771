import { createSlice } from "@reduxjs/toolkit";

import { addUser } from "../thunks/user/addUser";
import { reset } from "../actions/resetActions";
import { getUsersList } from "../thunks/user/userList";
import { getCountriesList } from "../thunks/user/countryList";
import { editUser } from "../thunks/user/editUser";
import { deleteUser } from "../thunks/user/deleteUser";

const initialState = {
    isLoading: false,
    error: null,
    success: null,
    userAdded: false,
    userList : null,
    countryList : null,
    userEdited : false,
    userDeleted : false
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        resetState: (state) => {
            state.error = null;
            state.success = null;
            state.isLoading = false;
            state.userAdded = false;
            state.userList = null;
            state.countryList = null;
            state.userEdited = false;
            state.userDeleted = false;
        },
        resetUserAdded : (state) => {
            state.userAdded = false;
        },
        resetError : (state) => {
            state.error = null
        },
        resetSuccess : (state) => {
            state.success = null
        },
        resetUserEdited : (state) => {
            state.userEdited = false;
        },
        resetUserDelete : (state) => {
            state.userDeleted = false;
        }
    },

    extraReducers: (builder) => {
        // reset
        builder.addCase(reset, () => initialState);

        // add user
        builder.addCase(addUser.pending, (state, action) => {
            state.isLoading = true;
            state.userAdded = null;
        });
        builder.addCase(addUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userAdded = true;
            state.success = action?.payload?.message;
        });
        builder.addCase(addUser.rejected, (state, action) => {
            state.isLoading = false;
            state.userAdded = false;
            state.error = action?.payload?.message;
        });

        // users list
        builder.addCase(getUsersList.pending, (state, action) => {
            state.isLoading = true;
            state.userList = null;
        });
        builder.addCase(getUsersList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userList = action?.payload?.data;
            state.success = action?.payload?.message;
        });
        builder.addCase(getUsersList.rejected, (state, action) => {
            state.isLoading = false;
            state.userList = null;
            state.error = action?.payload?.message;
        });

        // countries list
        builder.addCase(getCountriesList.pending, (state, action) => {
            state.isLoading = true;
            state.countryList = null;
        });
        builder.addCase(getCountriesList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.countryList = action?.payload?.data;
        });
        builder.addCase(getCountriesList.rejected, (state, action) => {
            state.isLoading = false;
            state.countryList = null;
            state.error = action?.payload?.message;
        });

        // edit user
        builder.addCase(editUser.pending, (state, action) => {
            state.isLoading = true;
            state.userEdited = null;
        });
        builder.addCase(editUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userEdited = true;
            state.success = action?.payload?.message;
        });
        builder.addCase(editUser.rejected, (state, action) => {
            state.isLoading = false;
            state.userEdited = false;
            state.error = action?.payload?.message;
        });

        // delete user
        builder.addCase(deleteUser.pending, (state, action) => {
            state.isLoading = true;
            state.userDeleted = null;
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userDeleted = true;
            state.success = action?.payload?.message;
        });
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.isLoading = false;
            state.userDeleted = false;
            state.error = action?.payload?.message;
        });
    }
})

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;