import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL, API_ENDPOINT_RESET_PASSWORD } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const resetPassword = createAsyncThunk("auth/resetPassword", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_RESET_PASSWORD}`, data);
    const responseData = await res.data;
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});
export { resetPassword };
