import {
  API_ENDPOINT_EDIT_PROFILE_DATA,
  API_ENDPOINT_UPLOAD_IMAGE,
  AUTH_TOKENS_KEY,
  CONTENT_TYPES,
} from "./constants";

import { jwtDecode } from "jwt-decode";
/**
 * Get the content type
 * @param {
 *      requestUrl : {Type : string}
 *      contentType : {Type : string}  
 * }  
 * @returns 
 */
export const getContentType = (
  requestUrl,
  contentType = CONTENT_TYPES?.APPLICATION_JSON
) => {
  if (!requestUrl) return;

  const formDataUrls = [
    API_ENDPOINT_UPLOAD_IMAGE,
    API_ENDPOINT_EDIT_PROFILE_DATA,
  ];
  const separator = "/api/";
  const separatorQueryString = "?";

  let searchString = stringToSearch(requestUrl, separator, false);

  if (searchString.includes(separatorQueryString)) {
    searchString = stringToSearch(searchString, separatorQueryString, true);
  }

  const isExists = formDataUrls.includes(searchString);
  if (isExists) {
    contentType = CONTENT_TYPES?.MULTIPART_FORM_DATA;
  }
  return contentType;
};

const stringToSearch = (stringToSplit, separator, isQueryString = false) => {
  const arrayOfStrings = stringToSplit.split(separator);
  if (!isQueryString) {
    const lastString = arrayOfStrings[arrayOfStrings.length - 1];
    return `${separator}${lastString}`;
  }
  return arrayOfStrings[0];
};

/**
 * Capitalize the first character of the given string after removing { , } from this
 * @param {string : Type :string} 
 * @returns capitalString
 */
export const capitalizeString = (string) => {
  let capitalString = string?.replace(/[{}']+/g, '')
  capitalString = capitalString[0].toUpperCase() + capitalString.slice(1)
  return capitalString
}


export const getParentsValues = (data) => {
  let parents = [];
  let values = [];
  let labels = [];
  let parValSum = [];

  for (let ele of data) {
    let sum = 0;
    for (let obj of ele?.Dishes) {
      sum += obj?.Count;
    }
    parValSum.push(sum);
  }

  for (let i = 0; i < data.length; i++) {
    let ele = data[i];
    for (let obj of ele?.Dishes) {
      parents.push(`${capitalizeString(ele?.Cuisine)} .`);
      values.push(obj?.Count * (ele.CuisinesCount / parValSum[i]));
      // Truncate label if it exceeds 3 words
      labels.push(`${capitalizeString(obj?.Dish)} ${ele.Cuisine}`);
    }
  }

  for (let i = data?.length - 1; i >= 0; i--) {
    parents.unshift("");
    values.unshift(data[i].CuisinesCount);
    labels.unshift(`${capitalizeString(data[i]?.Cuisine)} .`);
  }

  return {
    parents: parents,
    values: values,
    labels: labels
  };
};

/**
 * Search the user in the given data 
 * @param {
 *        searchedStr : Type:{string}
*         userData : Type:{Object}
 * } 
 * @returns 
 */
export const searchUser = (searchedStr, usersData) => {
  let newUserData = usersData?.filter(ele => {
    return (
      ele?.name.toLowerCase().includes(searchedStr.toLowerCase()) ||
      ele?.email.toLowerCase().includes(searchedStr.toLowerCase())
    )
  })
  return newUserData
}

/**
 * Round off the num
 * @param {num : Type:int}  
 * @returns 
 */
export const customRound = (num) => {
  if (num > 4.5) {
    return 5
  } else if (num > 3.5) {
    return 4
  } else if (num > 2.5) {
    return 3
  } else if (num > 1.5) {
    return 2
  } else if (num > 0.5) {
    return 1
  }
};

/**
 * Capitalize the first character of the given string after removing { , } from this
 * @param {str : Type :string} 
 * @returns words
 */
export const manipulateString = (str) => {
  str = str?.replace(/[{}']+/g, '')
  let words = str?.split(',');

  // Capitalize the first letter of each word
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i]?.trim()
    words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1);
  }

  // Join the words back together
  return words?.join(', ');
}
