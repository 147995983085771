import { BASE_URL, API_ENDPOINT_COUNTRY_LIST } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

const getCountriesList = createAsyncThunk("country/countryList", async (thunkAPI) => {
    try {
        const res = await axiosInstance.get(`${BASE_URL}${API_ENDPOINT_COUNTRY_LIST}`);
        const responseData = await res.data;
        return responseData;
    } catch (error) {
        return thunkAPI?.rejectWithValue(error?.response?.data);
    }
})

export { getCountriesList };
