import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import UserLeftBar from "../../shared/user-leftbar";

const UserAuth = () => {
    const { isSignedIn, role } = useSelector((state) => state.auth);
    const location = useLocation();

    // Define the paths that users with 'user' role should not have access to
    const restrictedPaths = ["/admin-list", "/add-account", "/edit-account"];

    // Check if the user is signed in and has the 'user' role
    if (isSignedIn && role === "user") {
        // Check if the current path is restricted
        if (restrictedPaths.includes(location.pathname)) {
            // If it's a restricted path, redirect to dashboard or other allowed paths
            return <Navigate to="/dashboard" replace />;
        } else {
            if (
                location.pathname === "/dashboard"
            ) {
                // Render UserLeftBar and Outlet for allowed paths for admin
                return (
                    <section className='flex justify-center gap-0 flex-col md:flex-row bg-light'>
                        <UserLeftBar />
                        <Outlet />
                    </section>
                );
            } else {
                // Render Outlet for other paths for admin
                return <Outlet />;
            }
        }
    } else if (isSignedIn && role === "admin") {
        // Check if the current path is one of the allowed paths for admin
        if (
            location.pathname === "/admin-list" ||
            location.pathname === "/dashboard"
        ) {
            // Render UserLeftBar and Outlet for allowed paths for admin
            return (
                <section className='flex justify-center gap-0 flex-col md:flex-row bg-light'>
                    <UserLeftBar />
                    <Outlet />
                </section>
            );
        } else {
            // Render Outlet for other paths for admin
            return <Outlet />;
        }
    } else {
        // If user is not signed in, redirect to home page
        return <Navigate to="/" state={{ from: location }} replace />;
    }
};

export default UserAuth;
