import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL, API_ENDPOINT_DELETE_USER } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

const deleteUser = createAsyncThunk("user/delete", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.put(`${BASE_URL}${API_ENDPOINT_DELETE_USER}`, data);
    const responseData = await res.data;
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});
export { deleteUser };
