import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from 'redux-persist/lib/storage/session'; // store persist data in sessionStorage

import { reset } from "./actions/resetActions";
import { authReducer, authActions } from "./slices/authSlice";
import { dashboardReducer, dashboardActions } from "./slices/dashboardSlice";
import { userReducer, userActions } from "./slices/userSlice";
import { dishesAction, dishesReducer } from "./slices/cuisinesSlice";

// Customize persist configuration for the dashboard slice
const dashboardPersistConfig = {
  key: "dashboard",
  storage : sessionStorage,
  whitelist: ["foodType", "ratingsChecked", "location1", "location2", "location3", 'allDropdown'], // Only whitelist these two properties
};

const persistedReducer = combineReducers({
  auth: authReducer,
  dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
  user: userReducer,
  dishes: dishesReducer,
});

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  version: 1,
};

const persistedRootReducer = persistReducer(rootPersistConfig, persistedReducer);

const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export {
  store,
  persistor,
  reset,
  authActions,
  dashboardActions,
  userActions,
  dishesAction,
};

export * from "./thunks/auth/login"
export * from "./thunks/user/addUser"
export * from "./thunks/auth/firstLogin"

export * from "./thunks/dashboard/filterData"

export * from "./thunks/user/userList"
export * from "./thunks/user/countryList"
export * from "./thunks/auth/forgotPasswod"
export * from "./thunks/auth/resetPassword"
export * from "./thunks/user/editUser"
export * from "./thunks/user/deleteUser"
export * from "./thunks/dashboard/sunBurstData"
export * from "./thunks/dashboard/categoryData"
export * from "./thunks/dashboard/cuisinesList"
export * from "./thunks/cuisines/fetchDishesOfCuisine"
export * from "./thunks/dashboard/dynamicDropDown"

