import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL} from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

//filter api call
const filterData = createAsyncThunk("dashboard/filter", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}/dashboard/getFilterData`, data);
    const responseData = await res.data;
    return {'location':data.level,res:responseData};
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});

//search count api call
const searchData = createAsyncThunk("dashboard/search", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}/dashboard/totalData`, data);
    const responseData = await res.data;
    return responseData;
  } catch (error) {
    return thunkAPI?.rejectWithValue(error?.response?.data);
  }
});

export { filterData, searchData };

