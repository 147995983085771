import { createSlice } from "@reduxjs/toolkit";

import { filterData, searchData } from "../thunks/dashboard/filterData";
import { sunBurstData } from "../thunks/dashboard/sunBurstData";
import { getCategoryData } from "../thunks/dashboard/categoryData";
import { getCuisinesData } from "../thunks/dashboard/cuisinesList";
import { dynamicDropDown } from "../thunks/dashboard/dynamicDropDown";
import { reset } from "../actions/resetActions";

const initialState = {
    isLoading: false,
    error: null,
    data: [],
    location1: [],
    location2: [],
    location3: [],
    searchData: {},

    sunburstChartData: null,
    success: null,
    categoryData: null,
    cuisinesData: null,
    ratingsChecked: [],
    pageNumber: 1,
    appliedFilter: true,
    countrySlugCuisine: localStorage.getItem("country_slug"),
    prevPage : 1,
    cuisinesLenghtCheck : false,
    reloaded : true,
    totalRecords : 0,
    foodType:1,
    isLoadingCuisineData : false,
    allDropdown : null
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        resetData: (state, action) => {
            state.error = "";
            state.isLoading = false;
            state.reloaded = true
        },
        setfilterData: (state, action) => {
            state.data = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setratingsChecked: (state, action) => {
            state.ratingsChecked = action.payload
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload
        },
        setAppliedFilter: (state, action) => {
            state.appliedFilter = action.payload
        },
        setCountrySlugCuisine: (state, action) => {
            state.countrySlugCuisine = action.payload
        },
        setPrevPage : (state, action) => {
            state.prevPage = action.payload
        },
        setCuisinesLenghtCheck : (state, action) => {
            state.cuisinesLenghtCheck = action.payload
        },
        setReloaded : (state, action) => {
            state.reloaded = action.payload
        },
        setCuisnesData : (state, action) => {
            state.cuisinesData = action.payload;
        },
        setFoodType : (state, action) => {
            state.foodType = action.payload;
        },

        setLocation1 : (state, action) => {
            state.location1 = action.payload;
        },
        setLocation2 : (state, action) => {
            state.location2 = action.payload;
        },
        setLocation3 : (state, action) => {
            state.location3 = action.payload;
        },
        setAllDropdown : (state, action) => {
            state.allDropdown = action.payload;
        },
        setLocations : (state, action) => {
            state['location' + action.payload] = [];
        }
    },

    extraReducers: (builder) => {
        // reset
        builder.addCase(reset, () => initialState);
        
        //Filter api execution
        builder.addCase(filterData.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(filterData.fulfilled, (state, action) => {
            state['location' + action.payload.location] = action.payload.res.data
            state.isLoading = false;

        });
        builder.addCase(filterData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload?.message;
        });

        //Search api execution
        builder.addCase(searchData.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(searchData.fulfilled, (state, action) => {
            state.searchData = action.payload.data
            state.isLoading = false;

        });
        builder.addCase(searchData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload?.message;
        });

        //Sunburst chart data api execution
        builder.addCase(sunBurstData.pending, (state) => {
            state.success = false;
        });
        builder.addCase(sunBurstData.fulfilled, (state, action) => {
            state.success = action?.payload?.message;
            state.sunburstChartData = action?.payload?.data;

        });
        builder.addCase(sunBurstData.rejected, (state, action) => {
            state.error = action?.payload?.message;
            state.success = null;
            state.sunburstChartData = null
        });
        //Bar Chart category
        builder.addCase(getCategoryData.pending, (state) => {
            state.categoryData = null;
        });
        builder.addCase(getCategoryData.fulfilled, (state, action) => {
            state.categoryData = action.payload.data
            state.success = action?.payload?.message;

        });
        builder.addCase(getCategoryData.rejected, (state, action) => {
            state.categoryData = null;
            state.error = action?.payload?.message;
        });

        //CUISINES DATA
        builder.addCase(getCuisinesData.pending, (state) => {
            // state.isLoading = true;
            // state.cuisinesData = null
            state.cuisinesLenghtCheck = false
            state.isLoadingCuisineData = true;
        });
        builder.addCase(getCuisinesData.fulfilled, (state, action) => {
            state.isLoadingCuisineData = false;
            if (action.payload.data?.length > 9) {
                state.cuisinesData = action.payload.data
                state.cuisinesLenghtCheck = false
            } else {
                if(action.payload.data?.length > 0){
                    state.cuisinesData = action.payload.data
                }
                state.cuisinesLenghtCheck = true
                if (state.pageNumber === 1) {
                    state.cuisinesData = action.payload.data
                }
            }
            state.success = action?.payload?.message;
            state.totalRecords = action?.payload?.totalRecords;

        });
        builder.addCase(getCuisinesData.rejected, (state, action) => {
            state.cuisinesData = null;
            state.error = action?.payload?.message;
            state.cuisinesLenghtCheck = false
            state.isLoadingCuisineData = false;
        });

         //Dropdown list
         builder.addCase(dynamicDropDown.pending, (state) => {
            state.allDropdown = null;
        });
        builder.addCase(dynamicDropDown.fulfilled, (state, action) => {
            state.allDropdown = action.payload.data[0]?.data
            state.success = action?.payload?.message;

        });
        builder.addCase(dynamicDropDown.rejected, (state, action) => {
            state.allDropdown = null;
            state.error = action?.payload?.message;
        });
    },
});

export const dashboardActions = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;