import axiosInstance from "../../../utils/axiosInstance"
import { BASE_URL,API_ENDPOINT_CUISINES_DISHES} from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

/** API to fetch data categorywise [cuisine,dish,drink] */

const fetchDishesOfCuisine = createAsyncThunk("cuisines/dishes", async (data, thunkAPI) => {
    try {
      const res = await axiosInstance.post(`${BASE_URL}${API_ENDPOINT_CUISINES_DISHES}`, data);
      const responseData = await res.data;
      return responseData;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.response?.data);
    }
  });
  export { fetchDishesOfCuisine };